import type React from 'react';

import { twMerge } from 'tailwind-merge';

import { ArrowRightIcon } from '~shared/assets/icons/arrows';
import tgStar from '~shared/assets/images/_tg-star.png';
import { Button } from '~shared/ui';

interface BoostButtonProps extends React.PropsWithChildren {
  className?: string;
}

export const BoostButton: React.FC<BoostButtonProps> = ({ className, children }) => {
  return (
    <Button
      className={twMerge('rounded-xl border border-white/10 px-4 py-2 text-md font-normal', className)}
      disabled
      variant="wrapper"
      size="wrapper"
    >
      <img className="mr-2 size-6" src={tgStar} alt="" />
      {children}
      <ArrowRightIcon className="ml-2 size-6" />
    </Button>
  );
};
