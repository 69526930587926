export enum AppRoutes {
  Root = '/',
  Projects = '/projects',
  Home = '/home',
  Tasks = '/tasks',
  Friends = '/friends',
  LevelFriends = '/friends/:level',
  AirDrops = '/airdrops',
  TermsAndConditions = '/terms-and-conditions',
  Top = '/top',
}
