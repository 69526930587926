import React, { useEffect } from 'react';

import { useStore } from 'app/store';
import { observer } from 'mobx-react';

import { useLatestJob } from '~entities/job';
import { AirDropCountdown } from '~features/airdrop-countdown';
import { BoostButton } from '~features/boost-button';
import { RefQr } from '~features/ref-qr';
import { UserBalance } from '~features/user-balance';
import { isDefined } from '~shared/lib';
import { Hedgehog, Page, Skeleton } from '~shared/ui';
import { JobStatusButton } from '~widgets/job-status-button/ui/job-status-button.component';

import { JOB_STATUS_MAP } from '../model';

export const HomePage: React.FC = observer(() => {
  const {
    jobStore: { getLatestJob },
    projectStore: { project },
    userStore: { user },
  } = useStore();
  const { latestJob, loaded } = useLatestJob();

  const colorScheme = isDefined(latestJob)
    ? JOB_STATUS_MAP[latestJob.status]
    : loaded
      ? JOB_STATUS_MAP.COMPLETED
      : null;

  useEffect(() => {
    if (isDefined(project) && isDefined(user)) {
      void getLatestJob(project.id ?? '', user.id ?? '');
    }
  }, [project, user, getLatestJob]);

  return (
    <Page className="items-center" background={colorScheme?.colorName ?? 'multicolor'}>
      {isDefined(project) && project.is_root ? (
        <Hedgehog className="mt-auto w-[108px]" color={colorScheme?.colorName ?? 'white'} withText />
      ) : isDefined(project) ? (
        <img className="mt-auto w-[120px] overflow-hidden rounded-full" src={project.logo_url ?? ''} alt="" />
      ) : (
        <Skeleton className="mt-auto h-[100px] w-[120px] rounded-full" />
      )}

      {isDefined(project) && !project.is_root && <h1 className="text-base font-normal uppercase">{project?.name}</h1>}

      <UserBalance className="mt-6" />

      <BoostButton className="mt-4">Double your rewards</BoostButton>

      <AirDropCountdown className="mt-6" project={project} />

      <RefQr
        className="mb-5 mt-8"
        qrHexColor={colorScheme?.qrColor ?? '#fff'}
        sphereColorClassName={colorScheme?.glowinSphereClass ?? 'bg-transparent'}
        usernameColorClassName={colorScheme?.textClass ?? 'text-white'}
      />

      <JobStatusButton className="mt-auto" />
    </Page>
  );
});
