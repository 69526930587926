import React, { useCallback, useEffect, useRef } from 'react';

import { useStore } from 'app/store';
import cn from 'classnames';
import { motion, useMotionValue } from 'framer-motion';
import { observer } from 'mobx-react';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import coinImage from '~shared/assets/images/_coin.png';
import { isDefined, useScreenDimensions } from '~shared/lib';

import { navLinks } from '../model/nav-links.model';

interface NavPanelProps {
  className?: string;
}

const NavPanel: React.FC<NavPanelProps> = observer(({ className }) => {
  const { pathname } = useLocation();
  const linksRefs = useRef<HTMLAnchorElement[]>([]);
  const indicatorLeft = useMotionValue(0);
  const indicatorWidth = useMotionValue(0);
  const { width: screenWidth } = useScreenDimensions();

  const {
    projectStore: { project },
  } = useStore();

  const handleNavigationClick = useCallback(() => {
    Telegram.WebApp.HapticFeedback.notificationOccurred('success');
  }, []);

  useEffect(() => {
    const linkIndex = navLinks.findIndex(({ path }) =>
      isDefined(
        matchPath(
          {
            path,
            caseSensitive: false,
            end: false,
          },
          pathname
        )
      )
    );

    if (linkIndex !== -1 && linkIndex < linksRefs.current.length) {
      indicatorLeft.set(linksRefs.current[linkIndex].offsetLeft + 10);
      indicatorWidth.set(linksRefs.current[linkIndex].clientWidth - 20);
    } else {
      indicatorLeft.set(0);
      indicatorWidth.set(0);
    }
  }, [pathname, indicatorLeft, indicatorWidth, screenWidth]);

  return (
    <nav
      className={twMerge(
        'fixed bottom-0 left-0 mx-auto box-border grid w-full grid-cols-[repeat(5,1fr)] bg-nav-panel p-3 text-xs font-semibold shadow-[inset_0_1px_0_0px] shadow-white/10',
        className
      )}
    >
      <motion.div
        className="absolute top-0 h-[2px] bg-blue transition-all"
        style={{ left: indicatorLeft, width: indicatorWidth }}
      />
      {navLinks.map(({ icon: Icon, label, path }, index) => {
        return (
          <NavLink
            className={({ isActive }) =>
              cn('flex flex-col items-center transition-all', isActive ? 'text-blue' : 'text-nav-panel-foreground')
            }
            ref={(ref) => {
              if (isDefined(ref)) {
                linksRefs.current[index] = ref;
              }
            }}
            onClick={handleNavigationClick}
            to={path}
            key={path}
          >
            {isDefined(Icon) ? (
              <Icon className="size-6" />
            ) : (
              <img
                className="size-6 object-cover object-center"
                src={isDefined(project) && !project.is_root ? project?.logo_url ?? '' : coinImage}
                alt=""
              />
            )}
            <span className="mt-[6px]">{label}</span>
          </NavLink>
        );
      })}
    </nav>
  );
});
NavPanel.displayName = 'NavPanel';

export { NavPanel };
