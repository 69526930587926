import { AirDropsIcon, FriendsIcon, TasksIcon, TopIcon } from '~shared/assets/icons/navigation';
import { AppRoutes } from '~shared/model';

export const navLinks = [
  {
    path: AppRoutes.Tasks,
    icon: TasksIcon,
    label: 'Tasks',
  },
  {
    path: AppRoutes.Top,
    icon: TopIcon,
    label: 'Top',
  },
  {
    path: AppRoutes.Home,
    icon: null,
    label: 'Farm',
  },
  {
    path: AppRoutes.Friends,
    icon: FriendsIcon,
    label: 'Friends',
  },
  {
    path: AppRoutes.AirDrops,
    icon: AirDropsIcon,
    label: 'AirDrops',
  },
] as const;
