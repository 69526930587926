import React, { useCallback } from 'react';

import { useStore } from 'app/store';
import { type VariantProps, cva } from 'class-variance-authority';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { QuestionIcon } from '~shared/assets/icons';
import { ChevronLeftIcon } from '~shared/assets/icons/chevrons';
import { isDefined } from '~shared/lib';
import { AppRoutes } from '~shared/model';

import { Button } from '../button';

export interface PageProps extends React.PropsWithChildren, VariantProps<typeof pageVariants> {
  className?: string;
}

const pageVariants = cva('flex h-full flex-col overflow-auto bg-cover bg-center bg-top px-6 pb-[112px] pt-3', {
  variants: {
    background: {
      blue: 'bg-blue-page',
      red: 'bg-red-page',
      green: 'bg-green-page',
      multicolor: 'bg-multicolor-page',
    },
  },
  defaultVariants: {
    background: 'multicolor',
  },
});

export const Page: React.FC<PageProps> = observer(({ children, background, className }) => {
  const {
    jobStore: { resetJob },
    projectStore: { selectProject, project, rootProject },
  } = useStore();
  const { pathname } = useLocation();

  const handleBackToGraphdex = useCallback(() => {
    if (isDefined(rootProject)) {
      resetJob();
      selectProject(rootProject);
    }
  }, [resetJob, rootProject, selectProject]);

  const termsAndConditionsMatch = matchPath(
    { path: AppRoutes.TermsAndConditions, end: false, caseSensitive: false },
    pathname
  );

  return (
    <div className={twMerge(pageVariants({ background }), className)}>
      {isNil(termsAndConditionsMatch) && (
        <div className="mb-8 flex w-full justify-end pb-3">
          {isDefined(project) && !project.is_root ? (
            <Button variant="link" className="p-0 text-base text-white" onClick={handleBackToGraphdex}>
              <ChevronLeftIcon className="mr-2 size-6 shrink-0" />
              Back to Graphdex
            </Button>
          ) : (
            <Button variant="link" className="p-0 text-base text-white" onClick={handleBackToGraphdex}>
              <Link className="flex items-center" to={AppRoutes.TermsAndConditions}>
                <QuestionIcon className="mr-2 size-6 shrink-0" />
                Terms and conditions
              </Link>
            </Button>
          )}
        </div>
      )}
      {children}
    </div>
  );
});
