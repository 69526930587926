import React from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import Gravatar from 'react-gravatar';

import { useUserBalance } from '~entities/user/lib';
import { BoostButton } from '~features/boost-button';
import { isDefined } from '~shared/lib';
import { Amount, Page, Skeleton } from '~shared/ui';

import { useTopUsers } from '../lib';

export const TopPage: React.FC = observer(() => {
  const {
    userStore: { user },
    projectStore: { project },
  } = useStore();

  const userBalance = useUserBalance();
  const {
    topUsers: { place1, place2, place3, rest: topUsersRest },
    userTopPosition,
  } = useTopUsers();

  return (
    <Page>
      <h1 className="text-center text-lg font-bold uppercase">Top</h1>

      <div className="mt-8 grid grid-cols-3 items-end justify-center gap-6">
        <div className="flex flex-col items-center">
          <div className="relative">
            {isDefined(place2) ? (
              <Gravatar className="rounded-full" email={place2.username} size={90} />
            ) : (
              <Skeleton className="size-[90px] rounded-full" />
            )}
            <span className="absolute bottom-[-20px] left-1/2 flex size-10 -translate-x-1/2 items-center justify-center rounded-full border-4 border-background bg-[#A57008] text-lg font-bold">
              2
            </span>
          </div>

          {isDefined(place2) ? (
            <p className="mt-7 text-center text-xs font-semibold">{place2.username}</p>
          ) : (
            <Skeleton className="mt-7 h-4 w-20" />
          )}

          {isDefined(place2) ? (
            <Amount
              className="mt-1 [&>*]:text-base [&>img]:mr-[2px] [&>img]:size-6"
              value={place2.balance}
              logo={isDefined(project) && !project.is_root ? project.logo_url : null}
            />
          ) : (
            <Skeleton className="mt-1 h-6 w-20" />
          )}
        </div>

        <div className="flex flex-col items-center">
          <div className="relative">
            {isDefined(place1) ? (
              <Gravatar className="rounded-full" email={place1.username} size={108} />
            ) : (
              <Skeleton className="size-[108px] rounded-full" />
            )}
            <span className="absolute bottom-[-20px] left-1/2 flex size-10 -translate-x-1/2 items-center justify-center rounded-full border-4 border-background bg-[#ECA00B] text-lg font-bold">
              1
            </span>
          </div>

          {isDefined(place1) ? (
            <p className="mt-7 text-center text-xs font-semibold">{place1.username}</p>
          ) : (
            <Skeleton className="mt-7 h-4 w-20" />
          )}

          {isDefined(place1) ? (
            <Amount
              className="mt-1 [&>*]:text-base [&>img]:mr-[2px] [&>img]:size-6"
              value={place1.balance}
              logo={isDefined(project) && !project.is_root ? project.logo_url : null}
            />
          ) : (
            <Skeleton className="mt-1 h-6 w-20" />
          )}
        </div>

        <div className="flex flex-col items-center">
          <div className="relative">
            {isDefined(place3) ? (
              <Gravatar className="rounded-full" email={place3.username} size={80} />
            ) : (
              <Skeleton className="size-[80px] rounded-full" />
            )}
            <span className="absolute bottom-[-20px] left-1/2 flex size-10 -translate-x-1/2 items-center justify-center rounded-full border-4 border-background bg-[#5E4004] text-lg font-bold">
              3
            </span>
          </div>

          {isDefined(place3) ? (
            <p className="mt-7 text-center text-xs font-semibold">{place3.username}</p>
          ) : (
            <Skeleton className="mt-7 h-4 w-20" />
          )}

          {isDefined(place3) ? (
            <Amount
              className="mt-1 [&>*]:text-base [&>img]:mr-[2px] [&>img]:size-6"
              value={place3.balance}
              logo={isDefined(project) && !project.is_root ? project.logo_url : null}
            />
          ) : (
            <Skeleton className="mt-1 h-6 w-20" />
          )}
        </div>
      </div>

      <div className="mt-8 flex flex-col items-center border-y border-border bg-background p-4">
        <h3 className="text-center font-bold">Your profile</h3>

        <div className="mt-4 flex items-center justify-center">
          {isDefined(user) ? (
            <Gravatar className="mr-6 rounded-full" email={user.id} size={60} />
          ) : (
            <Skeleton className="mr-6 size-[60px] rounded-full" />
          )}

          <div className="mr-6 flex flex-col items-start">
            <p className="text-xs font-semibold">Balance</p>
            {isDefined(userBalance) ? (
              <Amount
                className="mt-1 [&>*]:text-base [&>img]:mr-[2px] [&>img]:size-6"
                value={userBalance.formatedBalance}
                logo={isDefined(project) && !project.is_root ? project.logo_url : null}
              />
            ) : (
              <Skeleton className="mt-1 h-6 w-20" />
            )}
          </div>

          <div className="flex flex-col items-start">
            <p className="text-xs font-semibold">Rank</p>
            {isDefined(userTopPosition) ? (
              <p className="mt-1 font-bold">{userTopPosition.position_number}</p>
            ) : (
              <Skeleton className="mt-1 h-6 w-10" />
            )}
          </div>
        </div>

        <BoostButton className="mt-4">Boost your rank</BoostButton>
      </div>

      <h2 className="mt-8 text-md font-bold">Top Users</h2>

      <div className="mt-5 flex w-full flex-col space-y-2">
        {isNil(topUsersRest) &&
          Array(6)
            .fill(null)
            .map((_, index) => <Skeleton className="h-[84px] w-full" key={index} />)}
        {topUsersRest?.map((user, index) => (
          <div className="flex items-center rounded-xl bg-white/10 p-3 backdrop-blur-lg" key={index}>
            <Gravatar className="mr-3 rounded-full" email={user.username} size={60} />

            <div className="mr-3 flex flex-col items-start">
              <p className="text-xs font-semibold">{user.username}</p>
              <Amount
                className="mt-1 [&>*]:text-base [&>img]:mr-[2px] [&>img]:size-6"
                value={user.balance}
                logo={isDefined(project) && !project.is_root ? project.logo_url : null}
              />
            </div>

            <span className="ml-auto flex size-8 items-center justify-center rounded-full bg-nav-panel text-md font-bold">
              {index + 4}
            </span>
          </div>
        ))}
      </div>
    </Page>
  );
});
