import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';

import { useToast } from '~features/toaster/lib';
import { WidgetsTasksApiService, type ApiError, type UserTaskSchema } from '~shared/api/client';
import { isDefined } from '~shared/lib';
import { Amount, AnimatedCounter, Button, Drawer, DrawerContent, DrawerHeader, DrawerTitle } from '~shared/ui';

interface TaskDrawerProps {
  task: UserTaskSchema | null;
  onClose: (newOpen: boolean) => void;
  onComplete: (task: UserTaskSchema) => void;
}

export const TaskDrawer: React.FC<TaskDrawerProps> = observer(({ task, onClose, onComplete }) => {
  const {
    userStore: { user },
    projectStore: { project },
  } = useStore();

  const { toast } = useToast();

  const timerRef = useRef<NodeJS.Timer>();
  const [seconds, setSeconds] = useState<number | null>(null);
  const [isCompleteLoading, setIsCompleteLoading] = useState(false);

  const handleClose = useCallback(
    (newOpen: boolean) => {
      onClose(newOpen);
      if (!newOpen) {
        clearInterval(timerRef.current);
        setSeconds(null);
      }
    },
    [onClose]
  );

  const handleCompleteTask = useCallback(async () => {
    if (isNil(user) || isNil(user.id) || isNil(task) || isNil(task.id) || isNil(project) || isNil(project.id)) return;
    Telegram.WebApp.HapticFeedback.notificationOccurred('success');

    try {
      setIsCompleteLoading(true);
      const taskResponse =
        await WidgetsTasksApiService.completeApiV2WidgetUsersUserIdProjectsProjectIdTasksTaskIdCompletedPost(
          task.id,
          user.id,
          project.id
        );
      onComplete(taskResponse);
    } catch (error) {
      toast({
        title:
          ((error as ApiError)?.body as { detail: { msg: string } })?.detail?.msg ??
          ((error as ApiError)?.body as { detail: Array<{ msg: string }> })?.detail?.[0]?.msg ??
          'Unknown error',
      });
      timerRef.current = setInterval(() => {
        setSeconds((prev) => (isNil(prev) ? 10 : prev - 1));
      }, 1000);
      console.error('error');
    } finally {
      setIsCompleteLoading(false);
    }
  }, [user, project, task, toast, onComplete]);

  useEffect(() => {
    if (isDefined(seconds) && seconds <= 0) {
      clearInterval(timerRef.current);
      setSeconds(null);
    }
  }, [seconds, timerRef]);

  useEffect(() => {
    if (isDefined(task) && !task.is_completed) {
      timerRef.current = setInterval(() => {
        setSeconds((prev) => (isNil(prev) ? 10 : prev - 1));
      }, 1000);
      console.log('interval set', timerRef.current);
    }
  }, [task, timerRef]);

  return (
    <Drawer open={isDefined(task)} onOpenChange={handleClose}>
      <DrawerContent>
        <DrawerHeader className="flex flex-col items-center">
          {isDefined(task) && isDefined(task.type.logo_url) && (
            <img
              className="mb-8 size-[80px] overflow-hidden rounded-full object-cover object-center"
              src={task.type.logo_url}
              alt=""
            />
          )}
          <DrawerTitle>{task?.name}</DrawerTitle>
        </DrawerHeader>

        <Amount
          className="mt-8 [&>*]:text-2xl [&>img]:mr-1 [&>img]:size-10"
          value={`+${task?.amount}`}
          logo={project?.logo_url}
        />

        {(isNil(seconds) || seconds <= 0) && isDefined(task) && !task.is_completed && (
          <Button
            className="mt-8 h-[60px]"
            buttonColor="blue"
            isLoading={isCompleteLoading}
            onClick={handleCompleteTask}
          >
            Check
          </Button>
        )}

        {isDefined(seconds) && seconds > 0 && (
          <>
            <Button className="mt-8 h-[60px]" buttonColor="red" isLoading={isCompleteLoading}>
              <AnimatedCounter className="mr-[1ch] h-full" value={seconds.toString()} /> seconds
            </Button>
            <p className="absolute bottom-5 left-1/2 -translate-x-1/2 text-center text-2xs text-red">
              Activity not confirmed. Are you certain you completed this task?
            </p>
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
});
