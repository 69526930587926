import type React from 'react';
import { useCallback } from 'react';

import { useStore } from 'app/store';
import { observer } from 'mobx-react';

import { type UserTaskSchema } from '~shared/api/client';
import { CheckIcon, UncheckedIcon } from '~shared/assets/icons';
import { formatAmount, isDefined } from '~shared/lib';
import { Amount, Button } from '~shared/ui';

interface TaskListItemProps {
  task: UserTaskSchema;
  onClick: (task: UserTaskSchema) => void;
}

export const TaskListItem: React.FC<TaskListItemProps> = observer(({ task, onClick }) => {
  const {
    projectStore: { project },
  } = useStore();

  const handleTaskClick = useCallback(() => {
    Telegram.WebApp.HapticFeedback.notificationOccurred('success');
    onClick(task);
  }, [onClick, task]);

  return (
    <Button
      key={task.id}
      className="flex w-full items-center rounded-[10px] bg-white/10 p-3 backdrop-blur-lg disabled:opacity-100"
      asChild
      variant="wrapper"
      size="wrapper"
      disabled={task.is_completed}
      onClick={handleTaskClick}
    >
      <a href={task.link} target="_blank" rel="noreferrer">
        {isDefined(task.type.logo_url) && (
          <img
            className="mr-3 size-[46px] overflow-hidden rounded-full object-cover object-center"
            src={task.type.logo_url}
            alt=""
          />
        )}
        <div className="mr-3 flex flex-col">
          <p className="text-base font-normal">{task.name}</p>
          <Amount
            className="justify-start [&>*]:text-base [&>img]:mr-1 [&>img]:size-5"
            value={`+${formatAmount(parseFloat(task.amount))}`}
            logo={project?.logo_url}
          />
        </div>

        {task.is_completed ? <CheckIcon className="ml-auto size-6" /> : <UncheckedIcon className="ml-auto size-6" />}
      </a>
    </Button>
  );
});
