import type React from 'react';

import { useStore } from 'app/store';
import { observer } from 'mobx-react';

import { type UserInviteeTaskSchema } from '~shared/api/client';
import { CheckIcon, UncheckedIcon } from '~shared/assets/icons';
import { formatAmount } from '~shared/lib';
import { Amount } from '~shared/ui';

interface InviteeTaskListItemProps {
  task: UserInviteeTaskSchema;
  totalInvitees: number;
}

export const InviteeTaskListItem: React.FC<InviteeTaskListItemProps> = observer(({ task, totalInvitees }) => {
  const {
    projectStore: { project },
  } = useStore();

  return (
    <div className="flex w-full items-center rounded-[10px] bg-white/10 p-3 backdrop-blur-lg disabled:opacity-100">
      <div className="mr-3 flex flex-col">
        <Amount
          className="justify-start [&>*]:text-base [&>img]:mr-1 [&>img]:size-5"
          value={`+${formatAmount(parseFloat(task.amount))}`}
          logo={project?.logo_url}
        />
        <p className="mt-3 text-base font-normal">
          Invite <span className="rounded-full bg-blue px-2 py-[2px]">+{task.count}</span> friends
        </p>
      </div>

      {totalInvitees >= task.count ? (
        <CheckIcon className="ml-auto size-6" />
      ) : (
        <UncheckedIcon className="ml-auto size-6" />
      )}
    </div>
  );
});
