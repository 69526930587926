import React, { useCallback, useEffect, useState } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

import { WidgetsWalletsApiService, type UserProjectSchema } from '~shared/api/client';
import { ArrowRightIcon } from '~shared/assets/icons/arrows';
import { formatAmount } from '~shared/lib';
import { AppRoutes } from '~shared/model';
import { Amount, Button, Hedgehog } from '~shared/ui';

interface ProjectLinkProps {
  project: UserProjectSchema;
  onSelect: () => void;
}

export const ProjectLink: React.FC<ProjectLinkProps> = observer(({ project, onSelect }) => {
  const {
    projectStore: { selectProject },
    userStore: { user },
  } = useStore();
  const navigate = useNavigate();

  const [wallet, setWallet] = useState<string>('');

  const getProjectWallet = useCallback(async () => {
    if (isNil(user) || isNil(user.id) || isNil(project.id)) return;
    try {
      const walletResponse =
        await WidgetsWalletsApiService.getSelfWalletApiV2WidgetUsersUserIdProjectsProjectIdWalletsSelfGet(
          user.id,
          project.id
        );
      setWallet(walletResponse.address);
    } catch (error) {
      console.error(error);
    }
  }, [project, user]);

  const handleSelectProject = useCallback(() => {
    if ((project.is_root || wallet.length > 0) && project.is_active) {
      selectProject(project);
      navigate(AppRoutes.Home);
    } else {
      onSelect();
    }
  }, [onSelect, project, wallet, navigate, selectProject]);

  useEffect(() => {
    if (project.is_active) {
      void getProjectWallet();
    }
  }, [getProjectWallet, project]);

  return (
    <Button
      className="flex h-[72px] w-full overflow-hidden rounded-[10px] bg-white/10 backdrop-blur-lg"
      onClick={handleSelectProject}
      variant="wrapper"
      size="wrapper"
    >
      <div className="flex w-full items-center rounded-[10px] p-3">
        {project.is_root ? (
          <Hedgehog className="mr-3 size-10 shrink-0" color="blue" showGlowing={false} />
        ) : (
          <img className="mr-3 size-10 shrink-0 overflow-hidden rounded-full" src={project.logo_url ?? ''} alt="" />
        )}

        <div className="mr-auto flex flex-col items-start">
          <span>{project.name}</span>
          {project.is_active && (
            <Amount
              className="mt-1 justify-start [&>*]:text-base [&>img]:mr-1 [&>img]:size-5"
              value={`+${formatAmount(parseFloat(project.balance ?? ''))}`}
              logo={project?.logo_url}
            />
          )}
        </div>
      </div>

      <div className="ml-auto flex h-full items-center justify-center bg-blue p-7">
        <ArrowRightIcon className="size-6 text-white" />
      </div>
    </Button>
  );
});
