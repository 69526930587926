import React, { useEffect, useState } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';

import { AirDropCountdown } from '~features/airdrop-countdown';
import { ProjectLink } from '~features/project-link';
import { type UserProjectSchema } from '~shared/api/client';
import { isDefined } from '~shared/lib';
import { Page, Skeleton } from '~shared/ui';
import { ProjectDescriptionDrawer } from '~widgets/project-description-drawer';
import { ProjectDrawer } from '~widgets/project-drawer';
import { XAccountDrawer } from '~widgets/x-account-drawer';

export const ProjectsPage: React.FC = observer(() => {
  const {
    jobStore: { resetJob },
    projectStore: { projects, project, getProjects },
    userStore: { user },
  } = useStore();
  const [isXAccountDrawerOpen, setIsXAccountDrawerOpen] = useState(false);
  const [selectedProjectDrawerProject, setSelectedProjectDrawerProject] = useState<UserProjectSchema | null>(null);
  const [selectedProjectDescriptionProject, setSelectedProjectDescriptionProject] = useState<UserProjectSchema | null>(
    null
  );

  useEffect(() => {
    if (isDefined(user)) {
      void getProjects(user.id ?? '');
    }
  }, [user, getProjects]);

  useEffect(() => {
    resetJob();
  }, [resetJob]);

  return (
    <>
      <Page background="multicolor">
        <h1 className="text-center text-lg font-bold">AirDrops</h1>

        <AirDropCountdown className="mt-10" project={project} />

        <div className="mt-10 w-full flex-col space-y-2">
          {projects?.results.map((project) => (
            <ProjectLink
              key={project.id}
              project={project}
              onSelect={() => {
                if (project.is_active) {
                  setSelectedProjectDrawerProject(project);
                } else {
                  setSelectedProjectDescriptionProject(project);
                }
              }}
            />
          ))}
          {isNil(projects) &&
            Array(5)
              .fill(null)
              .map((_, index) => <Skeleton className="h-[72px] w-full" key={index} />)}
        </div>
      </Page>

      <ProjectDrawer
        project={selectedProjectDrawerProject}
        onClose={() => {
          setSelectedProjectDrawerProject(null);
        }}
      />

      <ProjectDescriptionDrawer
        project={selectedProjectDescriptionProject}
        onClose={() => {
          setSelectedProjectDescriptionProject(null);
        }}
      />

      <XAccountDrawer
        isOpen={isXAccountDrawerOpen}
        onClose={(newOpen) => {
          setIsXAccountDrawerOpen(newOpen);
        }}
      />
    </>
  );
});
