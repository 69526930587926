export enum TaskTabType {
  Top = 'top',
  Daily = 'daily',
  Regular = 'regular',
}

export const TaskTabs = [
  {
    type: TaskTabType.Top,
    label: 'Top',
  },
  {
    type: TaskTabType.Daily,
    label: 'Daily',
  },
  {
    type: TaskTabType.Regular,
    label: 'Regular',
  },
];
