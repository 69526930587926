import React from 'react';

import { Navigate, createBrowserRouter } from 'react-router-dom';

import { FriendsPage } from '~pages/friends';
import { HomePage } from '~pages/home';
import { LevelFriendsPage } from '~pages/level-friends';
import { ProjectsPage } from '~pages/projects';
import { TasksPage } from '~pages/tasks';
import { TermsAndConditionsPage } from '~pages/terms-and-conditions';
import { TopPage } from '~pages/top';
import { AppRoutes } from '~shared/model';
import { Layout } from '~widgets/layout';

export const appRouter = createBrowserRouter([
  {
    path: AppRoutes.Root,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to={AppRoutes.Home} />,
      },
      {
        path: AppRoutes.Home,
        element: <HomePage />,
      },
      {
        path: AppRoutes.Tasks,
        element: <TasksPage />,
      },
      {
        path: AppRoutes.LevelFriends,
        element: <LevelFriendsPage />,
      },
      {
        path: AppRoutes.Friends,
        element: <FriendsPage />,
      },
      {
        path: AppRoutes.AirDrops,
        element: <ProjectsPage />,
      },
      {
        path: AppRoutes.Top,
        element: <TopPage />,
      },
      {
        path: AppRoutes.TermsAndConditions,
        element: <TermsAndConditionsPage />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={{ pathname: AppRoutes.AirDrops, search: window.location.search }} />,
  },
]);
